<template>
  <div class="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-6 mb-6">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "ContentBody",
  // props: {
  //   horizontal: {type: Boolean, required: false, default: ()=>{return false}},
  // },
  methods: {
    // getStyle() {
    //   const wrapping = this.horizontal ? 'flex flex-wrap' : '';
    //   return `${wrapping} bg-white dark:bg-gray-700 rounded-lg shadow-lg p-6 mb-6`
    // }
  }
}
</script>
