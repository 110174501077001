<template>
  <pre class="bg-gray-900 text-white p-4 rounded-lg mb-4 w-max">
    <code v-if="isResponse" class="block text-blue-400">{{formattedJson(data)}}</code>
    <code v-else class="block text-green-500">{{formattedJson(data)}}</code>
  </pre>
</template>
<script>
export default {
  name: "CodeView",
  props: {
    data: {type: String, required: true},
    isResponse: {type: Boolean, default: () => {return false}},
  },
  methods: {
    formattedJson() {
      return JSON.parse(this.data);
    }
  }
}
</script>
