<template>
  <div v-if="!chunkFailed">
    <div v-if="loggedIn">
      <DashboardPage
          :request="request"
          :logged-in="loggedIn"
          :loading="loading"
          :user-data="userData"
          :icons="icons"
      />
    </div>
    <div v-else>
      <GuestPage  :icons="icons"/>
    </div>
  </div>
</template>
<script>
import {IconsUI} from "@/utils/IconsUI";
import GuestPage from "@/pages/GuestPage.vue";
import DashboardPage from "@/pages/DashboardPage.vue";
import RequestManager from "@/utils/RequestManager";

export default {
  name: "HomePage",
  components: {GuestPage, DashboardPage},
  props: {
    icons: {type: IconsUI, required: true},
    userData: {type: Object, required: true},
    loading: {type: Boolean, required: true},
    loggedIn: {type: Boolean, required: true},
    chunkFailed: {type: Boolean, required: true},
    request: {type: RequestManager, required: true},
  },
}
</script>
