<template>
  <div class="size-10 opacity-100 transform translate-x-0 bg-gradient-to-br from-pink-200 to-sky-200 rounded-lg flex items-center justify-center">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "GradLogo"
}
</script>
