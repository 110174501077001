<template>
  <footer class="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
<!--      <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">USMANHEART OÜ</span>-->
<!--      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />-->
      <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">{{ copyright }}</span>
    </div>
  </footer>
</template>
<script>

export default {
  name: "FooterPage",
  data () {
    return {
      copyright: "Copyright © 2022-Now",
    }
  }
}
</script>
