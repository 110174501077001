<template>
    <div class="absolute top-24 w-full h-auto p-8 bg-gray-200 text-gray-800 dark:bg-gray-900 dark:text-white">

      <!-- Title -->
      <ContentBody>
        <div class="text-2xl font-bold text-center mb-8">
          <div>We provide</div>
          <div>3D Rendering Services</div>
        </div>
      </ContentBody>

      <ContentBody class="flex items-center justify-center font-real3d space-x-10">

          <div>
            <div>You request:</div>
              <div>
                <CodeView :data="JSON.stringify(reqBody, null, 2)" />
              </div>
          </div>

        <div>
          <div>=></div>
        </div>

          <div>
            <div>Response:</div>
              <div>
                <CodeView :is-response="true" :data="JSON.stringify(resBody, null, 2)" />
              </div>
          </div>

      </ContentBody>

      <!-- Discord -->
      <ContentBody class="bg-gray-800">
        <div class="text-4xl font-bold text-center mb-8">
          Join us on Discord
        </div>
        <div v-for="contact in contactList" :key="contact.name" class="flex flex-col items-center space-y-4">
          <div :key="contact.name" class="text-xl font-semibold">{{ contact.name }}:</div>
          <a :key="contact.name" :href="contact.link" class="text-blue-300 hover:underline" target="_blank" rel="noopener noreferrer">{{ contact.link }}</a>
        </div>
      </ContentBody>

      <!-- Supported Platforms -->
      <ContentBody>
        <div class="mb-8">
          <div class="text-lg mb-4 flex justify-center items-center">Supported platforms</div>
          <div class="flex justify-center items-center space-x-4">
            <GradLogo><img class="size-10" :src="icons.bbl" alt="babylon.js" /></GradLogo>
            <GradLogo><img class="size-10" :src="icons.three" alt="three.js" /></GradLogo>
            <GradLogo><img class="size-8" :src="icons.unity" alt="unity3d" /></GradLogo>
            <GradLogo><img class="size-8" :src="icons.blend" alt="blend" /></GradLogo>
          </div>
        </div>
      </ContentBody>

      <!-- Platform Independent -->
      <ContentBody class="bg-gray-800">
        <div class="mb-8">
        <div class="text-lg mb-4 flex justify-center items-center">Platform independent</div>
        <div class="flex justify-center items-center space-x-4">
          <img class="size-8" :src="icons.jsx" alt="jsx" />
          <img class="size-8" :src="icons.node" alt="node" />
          <img class="size-8" :src="icons.rest" alt="rest" />
          <img class="size-8" :src="icons.python" alt="python" />
        </div>
      </div>
      </ContentBody>

      <FooterPage />
    </div>

</template>

<script>
import { IconsUI } from "@/utils/IconsUI";
import ContentBody from "@/views/contents/ContentBody.vue";
import FooterPage from "@/pages/FooterPage.vue";
import CodeView from "@/views/contents/CodeView.vue";
import GradLogo from "@/views/contents/GradLogo.vue";

export default {
  name: "GuestPage",
  components: {GradLogo, CodeView, FooterPage, ContentBody},
  props: {
    icons: { type: IconsUI, required: true },
  },
  data () {
    return {
      reqBody: {
        "cred": {
          "insID": "<Your Ins ID>",
          "appKey": "<Your App Key>",
          "prodKey": "<Your Prod Key>"
        },
        "type": "<Service Type>",
        "render": {
          "ext": "<Input file extension>",
          "expFrom": "<Platform>",
          "bake": false,
          "output": "<Output file extension>",
          "samples": 20,
          "quality": 512
        }
      },
      resBody: {
        "msg": "SUCCESS",
        "data": {
          "jobID": "1703925059_301526",
          "finished": false,
          "status": "NEW",
          "url": "<URL to upload>"
        }
      },
      contactList: [
        { name: 'Customer care', link: 'https://discord.gg/v8cgH4BNbm' },
        { name: 'Forums', link: 'https://discord.gg/ak7kQwjkte' },
      ]
    }
  }
}
</script>
